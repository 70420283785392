
  import { Component } from 'vue-property-decorator'
  import Cell from './cell'

@Component
  export default class DateCell extends Cell {
    get date () {
      const { item } = this
      if (!item) return ''

      return item.split(' ')[0]
    }

    get hour () {
      const { item } = this
      if (!item) return ''
      return item.split(' ').slice(1, 3).join(' ')
    }
  }
